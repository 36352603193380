export const accordion = () => {
  const accordions = document.querySelectorAll('.is-style-accordion') as NodeListOf<HTMLElement>;

  if (!accordions.length) return;

  accordions.forEach((accordion) => {
    const toggle = accordion.firstElementChild;

    if (!toggle) return;

    toggle.setAttribute('aria-expanded', 'false');
    accordion.setAttribute('aria-hidden', 'true');
    accordion.classList.add('init');

    toggle.addEventListener('click', () => {
      if (accordion.classList.contains('is-animating')) return;

      const isOpen = toggle.getAttribute('aria-expanded') === 'true';

      if (isOpen) {
        toggle.setAttribute('aria-expanded', 'false');

        accordion.classList.add('is-animating');

        accordion.style.minHeight = '';

        // close
        const animation = accordion.animate(
          [{ maxHeight: `${accordion.scrollHeight}px` }, { maxHeight: `${toggle.clientHeight}px` }],
          {
            duration: 300,
            easing: 'ease-in-out',
            fill: 'forwards',
          },
        );

        animation.onfinish = () => {
          accordion.setAttribute('aria-hidden', 'true');
          accordion.classList.remove('is-open');
          accordion.classList.remove('is-animating');
          accordion.style.maxHeight = '';
        };
      } else {
        toggle.setAttribute('aria-expanded', 'true');

        accordion.classList.add('is-animating');

        const animation = accordion.animate(
          [{ maxHeight: `${toggle.clientHeight}px` }, { maxHeight: `${accordion.scrollHeight}px` }],
          {
            duration: 300,
            easing: 'ease-in-out',
            fill: 'forwards',
          },
        );

        animation.onfinish = () => {
          accordion.setAttribute('aria-hidden', 'false');
          accordion.classList.add('is-open');
          accordion.classList.remove('is-animating');
          accordion.style.maxHeight = '';
        };
      }

      // close other accordions

      // accordions.forEach((otherAccordion) => {
      //   if (otherAccordion !== accordion) {
      //     const otherToggle = otherAccordion.firstElementChild;

      //     if (!otherToggle) return;

      //     if (!otherAccordion.classList.contains('is-open')) return;

      //     otherAccordion.classList.add('is-animating');

      //     const animation = otherAccordion.animate(
      //       [{ maxHeight: `${otherAccordion.scrollHeight}px` }, { maxHeight: `${otherToggle.clientHeight}px` }],
      //       {
      //         duration: 300,
      //         easing: 'ease-in-out',
      //         fill: 'forwards',
      //       },
      //     );

      //     animation.onfinish = () => {
      //       otherToggle.setAttribute('aria-expanded', 'false');
      //       otherAccordion.setAttribute('aria-hidden', 'true');
      //       otherAccordion.classList.remove('is-open');
      //       otherAccordion.classList.remove('is-animating');
      //       accordion.style.maxHeight = '';
      //     };
      //   }
      // });
    });
  });
};
