export const ffnMap = () => {
  const block = document.querySelector('.wp-block-ffn-map');

  if (!block) return;

  const popovers = block.querySelectorAll('.map__info-window');
  const paths = block.querySelectorAll('path[data-name], circle[data-name]');

  if (paths.length > 0 && popovers.length > 0) {
    paths.forEach((thisPath) => {
      const name = thisPath.getAttribute('data-name');
      const thisPopover = block.querySelector(`#${name}`);

      if (!thisPopover) return;

      let timeout: ReturnType<typeof setTimeout> | null = null;

      const handlePathMouseenter = () => {
        if (thisPath.classList.contains('active')) return;

        thisPath.classList.add('active');
        thisPopover.classList.add('active');

        const texts = block.querySelectorAll(`[data-text=${name}]`);
        if (texts.length) texts.forEach((text) => text.classList.add('active'));

        // remove active from other paths & windows
        paths.forEach((path) => {
          if (path !== thisPath) {
            path.classList.remove('active');

            const texts = block.querySelectorAll(`[data-text=${path.getAttribute('data-name')}]`);
            if (texts.length) texts.forEach((text) => text.classList.remove('active'));
          }
        });

        popovers.forEach((popover) => {
          if (popover !== thisPopover) {
            popover.classList.remove('active');
          }
        });
      };

      const handlePathMouseleave = (e: any) => {
        if (e.relatedTarget?.getAttribute('id') !== name) {
          // set 100ms delay
          timeout = setTimeout(() => {
            thisPath.classList.remove('active');
            thisPopover.classList.remove('active');

            const texts = block.querySelectorAll(`[data-text=${name}]`);
            if (texts.length) texts.forEach((text) => text.classList.remove('active'));
          }, 666);

          thisPath.addEventListener('mouseenter', () => {
            timeout && clearTimeout(timeout);
            timeout = null;
          });

          thisPopover.addEventListener('mouseenter', () => {
            timeout && clearTimeout(timeout);
            timeout = null;
          });
        }
      };

      const handleClick = () => {
        thisPath.classList.add('active');
        thisPopover.classList.add('active');

        popovers.forEach((popover) => {
          if (popover !== thisPopover) {
            thisPopover.classList.remove('active');
          }
        });
        paths.forEach((path) => {
          if (path !== thisPath) {
            path.classList.remove('active');
          }
        });
      };

      thisPath.addEventListener('mouseenter', handlePathMouseenter);
      thisPath.addEventListener('mouseleave', handlePathMouseleave);
      thisPath.addEventListener('click', handleClick);
    });

    popovers.forEach((infoWindow) => {
      const handleInfoWindowLeave = (e: any) => {
        const name = infoWindow.getAttribute('id');

        if (e.relatedTarget?.getAttribute('data-name') !== name) {
          const path = block.querySelector(`path[data-name="${name}"], circle[data-name="${name}"]`);

          if (!path) return;

          path.classList.remove('active');
          infoWindow.classList.remove('active');

          const texts = block.querySelectorAll(`[data-text=${name}]`);
          if (texts.length) texts.forEach((text) => text.classList.remove('active'));
        }
      };

      infoWindow.addEventListener('mouseleave', handleInfoWindowLeave);
    });
  }
};
