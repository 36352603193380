import { accordion } from './accordion';
import { desktopMenu } from './desktop-menu';
import { duotoneImage } from './duotone-image';
import { gforms } from './gforms';
import { headingUnderline } from './heading-underline';
import { loadMore } from './load-more';
import { mobileMenu } from './mobile-menu';
import { numbers } from './numbers';
import { scrollButton } from './scroll-button';
import { ffnMap } from './ffn-map';
import { moreLessButton } from './more-less-button';
import { navigatorMap } from './navigator-map';

const rdy = (fn: () => void) => {
  if (document.readyState !== 'loading') {
    fn();
    return;
  }
  document.addEventListener('DOMContentLoaded', fn);
};

// on dom content loaded
rdy(() => {
  desktopMenu(); // required before underline
  mobileMenu();
  duotoneImage();
  headingUnderline();
  numbers();
  accordion();
  scrollButton();
  loadMore();
  gforms();
  ffnMap();
  moreLessButton();
  navigatorMap();

  // make entire wp-block-image clickable in gallery
  const images = document.querySelectorAll('.wp-block-gallery .wp-block-image');

  images.forEach((image) => {
    const caption = image.querySelector('figcaption');

    if (caption) {
      (image as HTMLElement).style.cursor = 'pointer';
    }

    const link = image.querySelector('a');

    if (link) {
      image.addEventListener('click', () => {
        link.click();
      });
    }
  });
});
