export const duotoneImage = () => {
  const images = document.querySelectorAll(
    '.wp-block-cover.wp-duotone-blue-and-blue .wp-block-cover__image-background',
  );

  images.forEach((image) => {
    const img = image as HTMLImageElement;

    if (img.complete) {
      img.classList.add('is-loaded');
    } else {
      img.addEventListener('load', () => {
        img.classList.add('is-loaded');
      });
    }
  });
};
