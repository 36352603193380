export const desktopMenu = () => {
  // calc header height and set css var --header-height
  const header = document.querySelector('.site-header');

  if (!header) return;

  const menuItemsWithChildren = header.querySelectorAll('.menu-item-has-children');
  const menuLinks = header.querySelectorAll('a');

  const isDesktop = window.matchMedia('(min-width: 1024px)');

  menuItemsWithChildren.forEach((item) => {
    // wrap sub-menu in a div
    const subMenu = item.querySelector('.sub-menu');

    if (!subMenu) return;

    const subMenuWrapper = document.createElement('div');
    subMenuWrapper.classList.add('sub-menu-container');

    // get parent's color class
    const colors = ['red', 'orange', 'yellow', 'green', 'sky-blue'];
    const parent = item.closest('.menu-item');

    // add heading to submenu
    const heading = document.createElement('h2');
    heading.classList.add('wp-block-heading', 'is-style-underline');

    // get color for underline from menu item
    if (parent) {
      for (const className of parent.classList) {
        if (colors.includes(className)) {
          heading.classList.add(`has-${className}-background-color`);
        }
      }
    }

    heading.innerHTML = `${item.querySelector('a')?.textContent}`.replace(
      '& Supports',
      '<span class="whitespace-nowrap">& Supports</span>',
    );
    subMenuWrapper.appendChild(heading);

    subMenuWrapper.appendChild(subMenu);

    item.appendChild(subMenuWrapper);

    item.addEventListener('mouseenter', () => {
      if (!isDesktop.matches) return;

      item.classList.add('is-active');
    });
  });

  menuLinks.forEach((link) => {
    link.addEventListener('mouseenter', () => {
      if (!isDesktop.matches) return;

      menuItemsWithChildren.forEach((item) => {
        if (!item.contains(link)) {
          item.classList.remove('is-active');
        }
      });
    });
  });

  header.addEventListener('mouseleave', () => {
    if (!isDesktop.matches) return;

    menuItemsWithChildren.forEach((item) => {
      item.classList.remove('is-active');
    });
  });
};
