export const scrollButton = () => {
  const button = document.querySelector('.scroll-button');

  if (!button) return;

  button.addEventListener('click', () => {
    button.closest('.blocks > *')?.nextElementSibling?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  });
};
