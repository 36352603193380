import Splitting from 'splitting';

function calculateWidth(results: Splitting.Result[]) {
  results.forEach(({ words }) => {
    if (!words?.length) return;

    // calculate width of last line
    const firstWord = words[0];
    const lastWord = words[words.length - 1];
    const parent = firstWord.parentElement;

    if (!parent) return;

    const parentRect = parent.getBoundingClientRect();
    const parentX = parentRect.x;

    // get x position relative to parent
    const firstRect = firstWord.getBoundingClientRect();
    const lastRect = lastWord.getBoundingClientRect();

    // set var on root
    parent.style.setProperty('--line-start', `${firstRect.x - parentX}px`);
    parent.style.setProperty('--line-end', `${lastRect.x + lastRect.width - parentX}px`);
  });
}

export const headingUnderline = () => {
  const headings = Array.from(document.querySelectorAll('.wp-block-heading.is-style-underline')) as Element[];

  const results = Splitting({
    /* target: String selector, Element, Array of Elements, or NodeList */
    target: headings,
    /* by: String of the plugin name */
    by: 'words',
    /* key: Optional String to prefix the CSS variables */
    key: null,
  });

  calculateWidth(results);

  // on font load
  document.fonts.ready.then(() => {
    calculateWidth(results);
  });

  // on resize
  window.addEventListener('resize', () => {
    calculateWidth(results);
  });

  // when heading in view, add in-view class
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const heading = entry.target.closest('.wp-block-heading.is-style-underline') as HTMLElement;

      if (entry.isIntersecting) {
        heading.classList.add('is-animating');
        heading.classList.add('is-in-view');

        observer.unobserve(entry.target);

        setTimeout(() => {
          heading.classList.remove('is-animating');
        }, 650);
      }
    });
  });

  headings.forEach((heading) => {
    if (heading.lastElementChild) {
      observer.observe(heading.lastElementChild);
    }
  });
};
