import { CountUp } from 'countup.js';

export const numbers = () => {
  const els = document.querySelectorAll('.pattern-numbers span');

  if (!els.length) return;

  els.forEach((el) => {
    if (!el) return;

    // if first character is not a number, remove it
    const firstChar = el.textContent?.charAt(0);

    let prefix = '';

    if (firstChar && isNaN(+firstChar)) {
      prefix = firstChar;
    }

    let suffix = '';

    const lastChar = el.textContent?.charAt(el.textContent.length - 1);

    if (lastChar && isNaN(+lastChar)) {
      suffix = lastChar;
    }

    // remove all non-numeric characters before and after the number
    const value = el.textContent?.replace(/\D/g, '');

    if (!value) return;

    const countUp = new CountUp(<HTMLElement>el, +value, {
      startVal: Math.pow(10, value.length - 1),
      enableScrollSpy: true,
      scrollSpyOnce: true,
      prefix,
      suffix,
    });

    if (!countUp.error) {
      countUp.start();
    } else {
      // console.error(countUp.error);
    }
  });
};
