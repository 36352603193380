export const moreLessButton = () => {
  const moreButtons = document.querySelectorAll('.is-style-more');

  if (!moreButtons.length) return;

  moreButtons.forEach((moreButton) => {
    const container = moreButton.parentElement?.parentElement;

    if (!container) return;

    moreButton.addEventListener('click', () => {
      moreButton?.parentElement?.classList.add('is-expanded');

      if (container.classList.contains('is-style-accordion')) {
        // reset accordion height
        container.style.minHeight = `${container.scrollHeight}px`;
      }
    });

    const lessButton = container.querySelector('.is-style-less');

    lessButton?.addEventListener('click', () => {
      moreButton.parentElement?.classList.remove('is-expanded');
      container.style.minHeight = '';
    });
  });
};
