// cache results
const cache = new Map();

/**
 * Fetch paginated content from ACF blocks with data-load-more-* attributes
 */
export const loadMore = () => {
  const paginatedContent = document.querySelectorAll('[data-load-more-id]');

  if (!paginatedContent.length) return;

  const url = window.location.href.split('?')[0];

  paginatedContent.forEach((block) => {
    const button = block.querySelector('[data-load-more-button]') as HTMLAnchorElement;

    if (button) {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();

        fetchNextPage(button.href, block);
      });
    }

    const filters = block.querySelectorAll('[data-load-more-filter]');

    if (filters) {
      filters.forEach((filter) => {
        if (filter instanceof HTMLAnchorElement) {
          // link filter
          filter.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            // toggle active class
            filters.forEach((f) => {
              if (f !== filter) f.classList.remove('active');
              else f.classList.toggle('active');
            });

            // if filter is active, fetch new results, else reset
            const href = filter.classList.contains('active') ? filter.href : url;

            fetchNextPage(href, block, true, false);
          });
        } else if (filter instanceof HTMLButtonElement) {
          // button filter
          filter.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            // toggle active class
            filters.forEach((f) => {
              if (f !== filter) f.classList.remove('active');
              else f.classList.toggle('active');
            });

            // if filter is active, fetch new results, else reset
            const href = filter.classList.contains('active') ? `${url}?${filter.name}=${filter.value}` : url;

            fetchNextPage(href, block, true, false);
          });
        } else if (filter instanceof HTMLSelectElement) {
          // if filter is select element
          filter.addEventListener('change', (e) => {
            e.preventDefault();
            e.stopPropagation();

            // href = url + filter=value
            const href = `${url}?${filter.name}=${filter.value}`;

            fetchNextPage(href, block, true);
          });
        }
      });
    }

    // set initial page cache
    const id = block.getAttribute('data-load-more-id');
    const items = block.querySelectorAll('[data-load-more-list] > *');
    const nextPageLink = button?.href;
    const key = `${id}-${url}`;

    cache.set(key, { items, nextPageLink });
  });
};

/** Parse html string into dom nodes
 * @param {string} html
 */
const parse = (html) => {
  const doc = document.createElement('html');
  doc.innerHTML = html;
  return doc.lastElementChild;
};

/**
 * Fetch next page of paginated or filtered content
 * @param {Event} event
 * @param {HTMLElement} block
 * @param {boolean} isFilter
 * @param {boolean} useCache
 */
async function fetchNextPage(url, block, isFilter = false, useCache = true) {
  if (!url) return;

  const id = block.getAttribute('data-load-more-id');
  const list = block.querySelector('[data-load-more-list]');
  const button = block.querySelector('[data-load-more-button]');

  let items, nextPageLink;

  const key = `${id}-${url}`;

  // check cache
  if (useCache && cache.has(key)) {
    ({ items, nextPageLink } = cache.get(key));
  } else {
    // fetch html for next page from link's href
    try {
      const html = await fetch(url).then((rez) => rez.text());
      const fakeDom = parse(html);

      if (!fakeDom) return;

      const parent = fakeDom.querySelector(`[data-load-more-id=${id}]`);

      if (parent) {
        items = parent.querySelectorAll('[data-load-more-list] > *');
        nextPageLink = (parent.querySelector('[data-load-more-button]') as HTMLAnchorElement).href;

        // cache result
        cache.set(key, { items, nextPageLink });
      }
    } catch (err) {}
  }

  if (items.length > 0) {
    if (isFilter) list.innerHTML = '';

    items.forEach((item, i) => {
      list.appendChild(item);

      if (i === 0) {
        item.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    });
  }

  // update pagination link
  if (button) {
    if (nextPageLink) {
      button.href = nextPageLink;
      button.style.display = '';
    } else {
      button.style.display = 'none';
    }
  }
}
